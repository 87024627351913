import React from "react"
import Image from "../components/image"

import Layout from "../components/layout"
import {siteMetadata} from '../../gatsby-config'
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink, faPhone, faEnvelope, faAt, faPaperPlane, faCalendar, faComment } from "@fortawesome/free-solid-svg-icons"

const portfolio = { "flow": { "title": "Flow Institut Freiburg", "type": "Website Design", "url": "http:\/\/flow-institut-freiburg.de" }, "jugendfilmpreis": { "title": "Jugendfilmpreis", "type": "Website Design", "url": "http:\/\/jugendfilmpreis.de" }, "iff": { "title": "Indisches Filmfestival", "type": "Website Design", "url": "http:\/\/indisches-filmfestival.de" }, "schacht": { "title": "Schacht Immobilien", "type": "Website Design", "url": "http:\/\/schacht-immo.de" }, "geis": { "title": "Musikwoche Geislingen", "type": "Website Design", "url": "http:\/\/musikwoche-geislingen.de" }, "setmanager": { "title": "SetManager", "type": "Web application", "url": "http:\/\/setmanager.de" }, "djaka": { "title": "DJ Akademie Berlin", "type": "Relaunch", "url": "http:\/\/dj-akademie-berlin.de" }, "hpae": { "title": "Natuerheilpraxis Englerplatz", "type": "Website Design", "url": "http:\/\/naturheilpraxis-am-englerplatz.de" }, "jfc": { "title": "JugendFilmCamp Arendsee", "type": "Registration system", "url": "http:\/\/jugendfilmcamp.de\/Anmeldeformular" }, "fhps": { "title": "Freie Heilpraktikerschule Freiburg", "type": "Website Design", "url": "http:\/\/freie-heilpraktikerschule.de" }, "tisw": { "title": "Theater im Steinbruch", "type": "Website Design", "url": "http:\/\/theater-im-steinbruch.de" }, "ghse": { "title": "GHSE Schulen", "type": "Website Design", "url": "http:\/\/intranet.ghse.de" }, "redrain": { "title": "RedRain Cinema", "type": "Website Design", "url": "http:\/\/redraincine.com" }, "enok": { "title": "Enno Kastens", "type": "Website Design", "url": "http:\/\/ennokastens.de" }, "dorn": { "title": "Dorn-Therapie", "type": "Website Design", "url": "http:\/\/dorn-praxis.de" }, "rfpm": { "title": "Raum f\u00fcr Pr\u00e4senz", "type": "Website Design", "url": "http:\/\/praesenz.net" }, "gwis": { "title": "Heil-K\u00fcnste", "type": "Website Design", "url": "http:\/\/heil-kuenste.de" } }
console.log(portfolio)

const Page = () => (
    <Layout container={false}>
        <SEO title="Kontakt | Wir rufen Sie gerne zurück!" />

        <div className="page-header text-center mb-3" style={{color:"#222"}}>
            <h1 className="text-center mt-4 mb-1">Mehr erfahren?</h1>
            <p className="hidden-sm">Nutzen Sie das Formular oder eine<br/> der folgenden Kontaktmöglichkeiten:</p>

            <div className="mb-2">
                <a href={"tel:"+siteMetadata.phone} className="btn btn-primary-outline mt-2"><FontAwesomeIcon icon={faPhone}/> Anruf</a>
                <a href="https://calendly.com/ziegenhagel/call" target="_blank" className="btn btn-primary-outline"><FontAwesomeIcon icon={faCalendar}/> Termin <span className="hidden-sm">vereinbaren</span></a>
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%69%6E%66%6F%40%7A%69%65%67%65%6E%68%61%67%65%6C%2E%63%6F%6D" className="btn btn-primary-outline"><FontAwesomeIcon icon={faAt}/> E-mail</a>
            </div>
        </div>

      {/**
        <div style={{ marginTop: "60px" }}>
            <form action="https://api.ziegenhagel.com/sendmail.php" 
    acxtion="https://ow0qov2gpl.execute-api.eu-central-1.amazonaws.com/default/zghlContactRequest" method="post" className="bigform">
                <input name="field" placeholder="Ihre Telefonnummer"  />
                <input name="email" placeholder="Ihre Email" />
                <input name="subject" style={{display:"none"}} placeholder="Ihre Email" />
                <input name="to" style={{display:"none"}} value="__main" placeholder="Ihre Email" />
                <input name="phone" style={{display:"none"}} value="" placeholder="Bitte dieses Feld leer lassen" />
                <input name="msg" placeholder="Kurze Nachricht (optional)" />
                <button>Absenden <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></button>
            </form>
        </div>

 **/}
        <br />
    </Layout>
)

export default Page
